<template>
  <div class="vasion-main-inner-div flex-center">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div id="wrapper">
      <div class="md-layout-item vasion-page-title">
        Usage Dashboard
      </div>
      <div class="row">
        <div class="small-card">
          <div>
            <span>{{ totalWorkflows.toLocaleString() }}</span>
            <div class="card-text">
              Total Workflows
            </div>
          </div>
          <div class="icon-container" :style="{ backgroundColor : '#ffece5' }">
            <VasionTotalWorkflowsIcon id="other-workflow-icon" />
          </div>
        </div>
        <div class="small-card">
          <div>
            <span> {{ totalForms.toLocaleString() }} </span>
            <div class="card-text">
              Total Forms
            </div>
          </div>
          <div class="icon-container" :style="{ backgroundColor : '#e5f0fc' }">
            <VasionTotalFormsIcon id="files-labeled-icon" />
          </div>
        </div>
        <div class="small-card">
          <div>
            <span> {{ totalFolders.toLocaleString() }} </span>
            <div class="card-text">
              Total Folders
            </div>
          </div>
          <div class="icon-container" :style="{ backgroundColor : '#fff6e5' }">
            <VasionFoldersStackedIcon id="folders-stacked-icon" />
          </div>
        </div>
        <div class="small-card">
          <div>
            <span> {{ totalUsers.toLocaleString() }} </span>
            <div class="card-text">
              Total Users
            </div>
          </div>
          <div class="icon-container" :style="{ backgroundColor : '#ebe7eb' }">
            <VasionGroupIcon id="group-icon" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="medium-card">
          <span>Storage</span>
          <div class="chart-and-data">
            <VasionGaugeChart
              v-if="usedStoragePercentage > 0"
              colorProp="#ffab00"
              :percentProp="usedStoragePercentage"
              :heightProp="180"
              labelProp="Used Storage"
              class="gauge-chart"
            />
            <div class="data">
              <div class="data-row">
                <div class="data-item">
                  <div class="card-text">
                    Storage Used
                  </div>
                  <span> {{ storageUsed.toLocaleString() }} </span>
                </div>
                <div class="data-item">
                  <div class="card-text">
                    Total Folders
                  </div>
                  <span> {{ totalFolders.toLocaleString() }} </span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-item">
                  <div class="card-text">
                    Storage Allocated
                  </div>
                  <span> {{ storageAllocated.toLocaleString() }} </span>
                </div>
                <div class="data-item">
                  <div class="card-text">
                    Total Documents
                  </div>
                  <span> {{ totalDocuments.toLocaleString() }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="medium-card">
          <span>Users</span>
          <div class="chart-and-data">
            <VasionGaugeChart
              v-if="activeUsersPercentage > 0"
              colorProp="#400f3f"
              :percentProp="activeUsersPercentage"
              :heightProp="180"
              labelProp="Active Users"
              class="gauge-chart"
            />
            <div class="data">
              <div class="data-row">
                <div class="data-item">
                  <div class="card-text">
                    Total Users
                  </div>
                  <span> {{ totalUsers.toLocaleString() }} </span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-item">
                  <div class="card-text">
                    Logged in Users
                  </div>
                  <span> {{ activeUsers.toLocaleString() }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="large-card">
        <table class="date-range">
          <tr>
            <td class="w50">
              <VasionDateTimeField
                v-model="fromDate"
                label="From Date"
                type="date"
              />
            </td>
            <td class="w50">
              <VasionDateTimeField
                v-model="toDate"
                label="Through Date"
                type="date"
              />
            </td>
          </tr>
        </table>
        <VasionColumnChart
          id="columnChart"
          colorProp="#006be3"
          :dataProp="transactionsData"
          :labelsProp="labels"
          heightProp="330"
          titleProp="Transactions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'UsageDashboard',
  components: {
    Loading,
  },
  data() {
    return {
      activeUsersPercentage: 0,
      activeUsers: 0,
      fromDate: '',
      isLoading: false,
      labels: [],
      loaderBackgroundColor,
      loaderColor,
      storageAllocated: 0,
      storageUsed: 0.0,
      toDate: '',
      totalDocuments: 0,
      totalFolders: 0,
      totalFoldersAndSubfolders: 0,
      totalForms: 0,
      totalUsers: 0,
      totalWorkflows: 0,
      transactionsData: [],
      usedStoragePercentage: 0,
    }
  },
  computed: {
    dateData() { return `${this.fromDate}|${this.toDate}` },
    hasTransactionData() { return this.labels.length > 0 && this.transactionsData.length > 0 },
  },
  watch: {
    dateData() { this.getTransactionData() },
  },
  async created() {
    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat
    this.fromDate = this.getYesterday()
    this.toDate = this.getYesterday()
    this.getTransactionData()

    const stats = await this.$store.dispatch('admin/loadDashboardStatistics')
    this.setStatDetails(stats)
  },
  methods: {
    changeLabel(previousLabel, newLabel) {
      const index = this.labels.indexOf(previousLabel)
      if (index >= 0) {
        this.labels[index] = newLabel
      }
    },
    async getTransactionData() {
      this.isLoading = true
      const payload = {
        fromDate: this.fromDate,
        throughDate: this.toDate,
        searchOnMajorOnly: true,
      }
      const results = await this.$store.dispatch('admin/getTransactionData', payload)
      if (results?.Values && results.Values.length > 0) {
        this.labels = results.Values.map(e => e.majorName)
        this.transactionsData = results.Values.map(e => e.count)

        this.changeLabel('Automated Action', 'Automated Actions')
        this.changeLabel('Create Document', 'Documents Uploaded')
        this.changeLabel('Form Submission', 'Form Submissions')
        this.changeLabel('Initiate Workflow', 'Workflows Initiated')
        this.changeLabel('OCR Page', 'Pages OCRed')
        this.changeLabel('Signature Workflow Started', 'Signature Workflows Initiated')
      } else {
        this.labels = ['No Data']
        this.transactionsData = []
      }

      this.isLoading = false
    },
    getYesterday() {
      const today = new Date()
      today.setDate(today.getDate() - 1)

      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    setStatDetails(obj) {
      if (obj) {
        this.activeUsers = obj.activeUsers
        this.storageAllocated = obj.storageAllocated
        this.storageUsed = obj.storageUsed
        this.totalDocuments = obj.totalDocuments
        this.totalFolders = obj.totalFolders
        this.totalFoldersAndSubfolders = obj.totalFoldersAndSubfolders
        this.totalForms = obj.totalForms
        this.totalUsers = obj.totalUsers
        this.totalWorkflows = obj.totalWorkflows

        this.activeUsersPercentage = Math.round((this.activeUsers / this.totalUsers) * 100)
        this.usedStoragePercentage = Math.round((this.storageUsed / this.storageAllocated) * 100)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .vasion-main-inner-div {
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  // The width of the small-card affects if they are all displayed in one row or
  // wrap into multiple, at 395px the screen Minimum width before the cards start to wrap into other rows
  // is 1905px
  .small-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 392px; // 330px works for screens between 1905px - 1655px width
    height: 96px;
    padding: 24px;
    margin: 10px;
  }
  .medium-card {
    width: 800px; // 680px with 10px of margin works for screens in between 1905px - 1655px
    height: 298px;
    margin: 12px;
    padding: 24px 89px 48px 16px;
    .chart-and-data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 150px;
    }
    .data-row {
      display: flex;
      flex-direction: row;
      width: 300px;
      justify-content: space-between;
    }
    .data-item {
      width: 126px;
    }
  }
  .large-card {
    position: relative;
    width: 100%;
    margin-top: 10px;
    max-width: 1624px;
    padding: 16px 16px 32px;
  }
  #columnChart::v-deep .apexcharts-svg {
    min-height: 350px !important;
  }
  .small-card, .large-card, .medium-card {
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11), 0 4px 8px 0 rgba(0, 0, 0, 0.13);
    background-color: white;
  }
  .gauge-chart {
    width: 325px;
  }
  .icon-container {
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 24px;
  }
  span {
    width: 72px;
    height: 22px;
    margin: 0 6px 24px 0;
    font-family: Archivo;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
  }
  .card-text {
    margin: 9px 0;
    font-family: Archivo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-400;
  }
  #other-workflow-icon {
    fill: $orange-300;
  }
  #files-labeled-icon {
    fill: $blue-300;
  }
  #folders-stacked-icon {
    fill: #ffab00;
  }
  #group-icon {
    fill: $plumb-300;
  }
  #wrapper {
    max-width: 1700px;
  }
  .flex-center {
    display: flex;
    justify-content: center;
  }
  .date-range {
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 5%;
  }
  .w50 {
    width: 50%;
    padding-right: 10px;
  }
</style>
